@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6dcb8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.touch { -ms-touch-action: pan-y; touch-action: pan-y; }

.swiper {
  height: 200px;
  width: 912px;
  border: solid 4px #DDBD6D;
  border-radius: 0.75rem;
  margin: 10px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#pagination .container{
  display: flex;
  list-style: none;
}

#pagination .page{
	padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
}

#pagination .disabled{
    cursor: not-allowed;
    
}

#pagination .active{
	border: 2px solid #000;
    font-weight: bold;
}

#pagination .previous{
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
}

#pagination .break{
    padding: 10px;
}

#pagination .next{
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display:none;
  /* height: 24px;
  width: 24px; */
}

/* Color/shape of close button cross */
.bm-cross {
  background: #373a47;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 50px;
  height: 100%;
  bottom: 0px;
}

/* General sidebar styles */
.bm-menu {
  background: #E6DCB8;
  padding: 0 1.5em 0;
  font-size: 0.875rem;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #000;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* gallery specific CSS */

.-fx-image-gal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 85%; /* arbitrary valye */
  margin: 0px auto;
}
.-fx-gal-item {
  width: 18%; /* for 3 columns */
  margin: 5px;
  overflow: hidden;
  border-radius: 15px;
}

.-fx-gal-image-thumb img {
  width: 100%;
  border-radius: 15px;
  cursor: pointer;
  /* -webkit-filter: grayscale(80%);
  -moz-filter: grayscale(80%);
  filter: grayscale(80%); */
  transition: all 0.3s ease;
}

.-fx-gal-image-thumb:hover img {
  width: 100%;
  cursor: pointer;
  /* -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%); */

  transform: scale(1.2);
  transition: all 0.5s ease;
}

.-fx-gal-image-thumb:focus + .-fx-gal-image-big {
  display: block;
}

.-fx-gal-image-big {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(5, 10, 15, 0.8);
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  transition: all 0.3s ease;
}

.-fx-gal-image-big img {
  max-width: 90vw;
  position: absolute;
  box-shadow: 0px 0px 800px 40px rgba(0, 0, 0, 0.9);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}